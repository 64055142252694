import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n3 from "../../images/news3.jpg";
import NFooter from "../../components/nFooter";

const NewsOne = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="iTelaSoft Leaps into Narrowband IoT"
        description="The IoT Test Platform iTelaSoft developed, helps Telcos to evaluate the platform readiness and Solution Providers to plan device deployments. The platform simulates the load demand in the IoT platform and identify potential bottlenecks in the messaging pipeline."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img news3-bnr">
          <img alt="narrowband IoT" src={n3} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">NEWS | JUNE 3, 2018</div>
              <h1>iTelaSoft Leaps into Narrowband IoT</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  Internet of Things (IoT) is a network of online capable
                  physical devices, vehicles, home appliances and many other
                  items. An estimated 30 million objects would be connected to
                  it globally by 2020 with global market value reaching $7.1
                  trillion. Narrowband networking of IoT is more effective, low-
                  cost, long coverage and a promising technology for tomorrow.
                  Announcing its active engagement in the Narrowband IoT
                  Solution space, iTelaSoft has recently come up with one of its
                  latest innovations, IoT Test Platform providing an invaluable
                  tool for feasibility testing and deployment planning of IoT
                  solutions for both Telcos and IoT implementers. Having helped
                  one of the largest global Telcos to assess the readiness of
                  their IoT platform for real-world demand, iTelaSoft is now
                  ready to take up the Narrowband IoT challenges globally.
                </p>
                <p>
                  Explaining further, Mr. Chrishmal Fernando, Lead Engineer
                  said, “Narrowband IoT solutions are still emerging, but
                  there’s a significant interest in the market. Both in Europe
                  and Americas, mobile network operators have been preparing for
                  Narrowband services for the last few years. It’s fast
                  spreading to the APAC region too! However, still there are so
                  many gaps in the ecosystem with availability of the narrowband
                  capability, message hub load handling, protocol compliance
                  etc. This is where site tests, message tests, load simulation
                  and power consumption monitoring come in handy. The IoT Test
                  Platform we have developed, helps Telcos to evaluate the
                  platform readiness and Solution Providers to plan device
                  deployments. With a recent exercise we have done with a large
                  global Telco, we helped them to simulate the load demand in
                  their IoT platform, identifying potential bottlenecks in the
                  messaging pipeline.”
                </p>
                <p>
                  Narrowband IoT is gaining attention across the world. Software
                  Engineering Lead, Mr. Manoj Fernando added, “The ability to
                  cover a large number of devices, wide & deep reception and low
                  power consumption are compelling reasons why this technology
                  is gaining rapid momentum. We have been building solutions in
                  the IoT space using Wifi, Bluetooth and LoRA as the
                  connectivity technology. Narrowband IoT adds a different value
                  proposition in IoT solution building. We are talking about
                  hundreds or thousands of sensors/actuators running
                  autonomously for years. In those scenarios, device security,
                  health monitoring, exception monitoring and governance have to
                  be well thought-out and planned. A well-managed narrow band
                  device management platform provides faster rollout,
                  reliability, security and peace of mind for IoT solution
                  builders. With our frameworks and services at iTelaSoft, we
                  help both solution builders as well as Telcos”. Adding to
                  that, Mr. Tharindu Hettiarachchi, General Manager of iTelaSoft
                  mentioned, “Narrowband IoT has immense potential for many
                  innovative applications. According to GSMA, both CAT-NB1 (NB
                  IoT) and CAT-M1 (LTE-M) will be part of the 5G standard. I had
                  the privilege of working in the iTelaSoft IoT team from the
                  very beginning, and I’m proud to be involved with the
                  innovative solutions we have built which positively impact our
                  customers”.
                </p>
                <p>
                  “iTelaSoft has been working with IoT Devices and Solutions for
                  the last few years. We are a passionate bunch of electronic
                  and software engineers, taking pride in innovation and problem
                  solving.”, said Indaka Raigama, CEO, iTelaSoft. “Over the last
                  few years we created some interesting solutions combining our
                  electronic, software product engineering and data science
                  capabilities. One such solution was a performance monitoring
                  and system monitoring platform for one of the largest mobile
                  money providers in the world. We wanted to invest in building
                  capability in Narrowband IoT technologies. I’m proud of what
                  our team has done in this sector and helping our customers to
                  deliver robust solutions. Our R&D centres are located in Sri
                  Lanka and I’m very excited to see the progress made by the two
                  key network operators, Dialog and Mobitel in Narrowband
                  capability; which is way ahead in the region! I’m thankful for
                  all their support and collaboration in our IoT journey.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <NFooter
          prv1="/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
          prv2="/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
          next1="/noteworthy/itelasoft-night-2019"
          next2="/noteworthy/itelasoft-night-2019"
          titlel="Former Navantis CEO Joins iTelaSoft"
          titler="iTelaSoft Celebrating Global Success"
        />
      </div>
    </Layout>
  );
};

export default NewsOne;
